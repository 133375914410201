import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';

import SEO from '../components/seo';

import { Menu } from '../styles/styles.js';

const Item = styled.div`
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px dotted #555;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.25);
  h4 {
    font-family: 'Roboto', 'sans-serif';
  }
  .item-title {
    margin-right: 15px;
  }
  .item-price-wrapper {
    display: flex;
  }
  .item-price {
    margin-right: 15px;
    color: #00803d;
    @media (max-width: 375px) {
      margin-right: 10px;
    }
    span {
      font-size: 0.813rem;
      font-weight: 400;
      text-transform: uppercase;
      color: #777;
    }
  }
  .item-desc {
    color: #777;
    margin-bottom: 10px;
  }
`;

const Pizzas = ({ data }) => {
  return (
    <>
      <SEO
        title="Pizzas"
        keywords={[
          `Pizzas`,
          `Batata`,
          `Batata com Bacon`,
          `filé de frango`,
          `Porção de Calabresa`,
        ]}
      />
      <Menu className="menu">
        <Slide left cascade>
          <h1 className="menu-title">Pizzas</h1>
        </Slide>

        <Fade left>
          <div className="item-image">
            <Img
              fluid={data.pizzas.childImageSharp.fluid}
              alt="Pizzas vários sabores"
            />
          </div>
        </Fade>

        <Item>
          <h4 className="item-title">Pizza de Bacon</h4>

          <p className="item-desc">
            Molho de tomate, queijo, tomate, bacon cebola e azeitona.
          </p>

          <div className="item-price-wrapper">
            <h4 className="item-price">
              <span>Grande</span> 75,00
            </h4>
          </div>
        </Item>

        <Item>
          <h4 className="item-title">Pizza de Calabresa</h4>

          <p className="item-desc">
            Molho de tomate, queijo, calabresa, cebola, tomate, orégano e
            azeitona.
          </p>

          <div className="item-price-wrapper">
            <h4 className="item-price">
              <span>Grande</span> 75,00
            </h4>
          </div>
        </Item>

        <Item>
          <h4 className="item-title">Pizza de Frango com Catupiry</h4>

          <p className="item-desc">
            Molho de tomate, queijo, catupiry, frango, orégano e azeitona.
          </p>

          <div className="item-price-wrapper">
            <h4 className="item-price">
              <span>Grande</span> 85,00
            </h4>
          </div>
        </Item>

        {/* <Item>
          <h4 className="item-title">Pizza Quatro Queijos</h4>

          <p className="item-desc">
            Molho de tomate, queijo provolone, queijo prato, queijo parmesão e
            queijo mussarela.
          </p>

          <div className="item-price-wrapper">
            <h4 className="item-price">
              <span>Média</span> 75,00
            </h4>
            <h4 className="item-price">
              <span>Grande</span> 85,00
            </h4>
          </div>
        </Item>

        <Item>
          <h4 className="item-title">Pizza Moda da Casa</h4>

          <p className="item-desc">
            Molho de tomate, queijo, presunto, ovo de codorna, milho, palmito,
            calabresa, lombinho, tomate, cebola, orégano e azeitona.
          </p>

          <div className="item-price-wrapper">
            <h4 className="item-price">
              <span>Média</span> 75,00
            </h4>
            <h4 className="item-price">
              <span>Grande</span> 85,00
            </h4>
          </div>
        </Item> */}

        <Item>
          <h4 className="item-title">Pizza de Strogonoff de Filé</h4>

          <p className="item-desc">
            Molho de tomate, queijo, strogonoff de carne, azeitona, batata palha
            e orégano.
          </p>

          <div className="item-price-wrapper">
            <h4 className="item-price">
              <span>Grande</span> 85,00
            </h4>
          </div>
        </Item>

        {/* <Item>
          <h4 className="item-title">Pizza de Beijinho</h4>

          <p className="item-desc"></p>

          <div className="item-price-wrapper">
            <h4 className="item-price">
              <span>Média</span> 75,00
            </h4>
            <h4 className="item-price">
              <span>Grande</span> 85,00
            </h4>
          </div>
        </Item>

        <Item>
          <h4 className="item-title">Pizza de Brigadeiro</h4>

          <p className="item-desc"></p>

          <div className="item-price-wrapper">
            <h4 className="item-price">
              <span>Média</span> 75,00
            </h4>
            <h4 className="item-price">
              <span>Grande</span> 85,00
            </h4>
          </div>
        </Item> */}
      </Menu>
    </>
  );
};

export const query = graphql`
  query {
    pizzas: file(relativePath: { eq: "pizzas.png" }) {
      childImageSharp {
        fluid(maxWidth: 350, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default Pizzas;
